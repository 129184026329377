import React from "react";


function TandelPage1({data}) {

  return (
    <div className="container">
      <div className="ccard">
        <div className="row ">
          <div className="col col-bg f18">Purchaser</div>
          <div className="col f18">₹ {data["pur"].toLocaleString('en-in')      }</div>
        </div>
        <div className="row">
          <div className="col col-bg f18">Diesel</div>
          <div className="col f18">₹ {data["diesel"].toLocaleString('en-in')      }</div>
        </div>
        <div className="row">
          <div className="col col-bg f18">Ice</div>
          <div className="col f18">₹ {data["ice"].toLocaleString('en-in')      }</div>
        </div>
        <div className="row ">
          <div className="col col-bg bol f22">Profit</div>
          <div className="col f22">₹ {data["profit"].toLocaleString('en-in')      }</div>
        </div>
        <div className="row ">
          <div className="col col-bg  ">{data['profit_percent']}% of Profit</div>
          <div className="col ">₹ {Math.round(data["profit"] * data['profit_percent_dec']).toLocaleString('en-in')}</div>
        </div>
      </div>
      {data["tan"].map((item) => {
        return (
          <div className="ccard pcard">
            <div className="row ">
              <div className="col col-bg bol f22">{item[0]}</div>
            </div>
            <div className="row ">
              <div className="col col-bg  ">{item[3] * 100}% of Profit</div>
              <div className="col ">₹ {item[1].toLocaleString('en-in')}</div>
            </div>
            <div className="row ">
              <div className="col col-bg  ">Salary {item[3] * 100000} x {item[7]}</div>
              <div className="col ">₹ {item[2].toLocaleString('en-in')}</div>
            </div>
            <div className="row ">
              <div className="col col-bg  "></div>
              <div className="col ">₹ {item[4].toLocaleString('en-in')}</div>
            </div>
            <div className="row ">
              <div className="col col-bg  ">less Advance</div>
              <div className="col ">₹ {item[5].toLocaleString('en-in')}</div>
            </div>
            <div className="row ">
              <div className="col f22 bol">₹ {item[6].toLocaleString('en-in')}</div>
            </div>
          </div>
        );
      })}
    </div>
  );


}

export default TandelPage1;
